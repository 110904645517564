import util from "../../../../util/util";
import {timeout} from "@cloudlace/client"
/**
 * Mixin with common sign flow creation features.
 */
export default {
    methods : {
        /**
         * Precreate signflow.
         */
        preCreateSignFlow()
        {
            if( this.signatureLevel === "advanced" && this.walletEnabled )
            {
                util.checkWallet( this.$store.shioCoreClient, this.signflow_estimated_cost, this.subscription?.address?.country ).then( r =>
                {
                    if( r )
                    {
                        this.createSignFlow();
                    }
                    else
                    {
                        this.$alert( {
                            text : this.$t( "wallet.Error!InsufficientWalletBalance" ),
                            acceptText : this.$t( "signflow.Ok" )
                        } );
                    }
                } ).catch( e =>
                {
                    console.error( e );
                    this.$alert( {
                        text : this.$t( "signflow.Error!Unexpected" ),
                        acceptText : this.$t( "signflow.Ok" )
                    } ).then( () =>
                    {
                        this.terminate( e );
                    } );
                } );
            }
            else
            {
                this.createSignFlow();
            }
        },
        /**
         * Create the sign flow.
         */
        createSignFlow()
        {
            this.is_working = true;
            const msg = this._getSubmitMessage();
            this.$store.signFlowClient.createSignFlow( msg.groupId, msg.process, msg.signers, msg.documents ).then(
            r =>
            {
                delete this.$store.flow_to_create;
                this.processUuid = r.processUuid;
                this.groupId = r.groupId;
                console.debug( "[StartFlow] Created sign flow." );
                this._pollSignFlow( 2000 );
            } ).catch( e =>
            {
                this.$alert( {
                    text : this.$t( "signflow.Error!Unexpected" ),
                    acceptText : this.$t( "signflow.Ok" )
                } ).then( () =>
                {
                    this.terminate();
                } );
            } );
        },
        /**
         * Stub. Implement what's needed to reset the control here.
         */
        reset()
        {
        },
        /**
         * Stub. Implement what's needed to finish flow creation here.
         *
         * @param err {Error?}
         */
        terminate( err )
        {
            this.is_working = false;
        },
        /**
         * Return the request map for creating a sign flow.
         *
         * @returns {{signers, process : {requester : {name}, emailMessage, groupTitle, title, locale : string,
         * emailSubject}, documents, groupId : string}}
         * @private
         */
        _getSubmitMessage()
        {
            let receiptVersion;
            const out = {
                groupId : $cc_user_info.organization.uuid,
                process : {
                    groupTitle : $cc_user_info.organization.display_name,
                    title : this.flowTitle,
                    emailSubject : this.choose_signature ? this.emailSubject : undefined,
                    emailMessage : this.choose_signature ? this.emailMessage : undefined,
                    requester : {
                        name : $cc_user_info.name
                    },
                    locale : util.getLocale(),
                    signatureLevel : this.signatureLevel || "basic"
                },
                signers : this.signers.map( signer =>
                {
                    return {
                        signerType : signer.signerType,
                        signerName : signer.signerName,
                        signerEmail : signer.signerEmail,
                        locale : signer.locale,
                        allowedSignatureTypes : (this.signatureLevel === "advanced") ?
                        signer.allowedSignatureTypes.filter( item => item !== "email-based-signature" ) : []
                    }
                } ),
                documents : this.documents.map( document =>
                {
                    return {
                        token : document.token,
                        fileName : document.fileName.normalize( 'NFC' ),
                        size : document.size,
                        signatures : document.signatures ? document.signatures.map( sig =>
                        {
                            receiptVersion = 2;
                            return {
                                page : sig.page,
                                top : sig.top,
                                left : sig.left,
                                height : sig.height,
                                width : sig.width,
                                scale : sig.scale,
                                textAlign : sig.textAlign,
                                shouldFillBackground : sig.shouldFillBackground
                            }
                        } ) : undefined
                    }
                } )
            };
            if( receiptVersion )
            {
                out.process.signatureReceiptVersion = receiptVersion;
            }
            return out;
        },
        /**
         * Set timeout to retrieve status of just created signFlow.
         *
         * @param millis {number}
         */
        _pollSignFlow( millis )
        {
            if( this.processUuid )
            {
                timeout.setTimeout( () => this._doPollSignFlow(), millis || 0, "poll-sign-flow" );
            }
            else
            {
                timeout.clearTimeout( "poll-sign-flow" );
            }
        },
        /**
         * Retrieve sign flow matching .groupId and .processUuid.
         */
        _doPollSignFlow()
        {
            this.$store.signFlowClient.retrieveSignFlow( this.groupId, this.processUuid ).then( signFlow =>
            {
                if ( signFlow.canceled )
                {
                    let msg = "";
                    // create_error_code_0: createSignFlow error
                    // create_error_code_1: createReceiptPages error
                    // create_error_code_2: createDocuments error
                    // create_error_code_3: createSignInvitations error
                    // create_error_code_4: createProcessCreatedEvent error
                    // create_error_code_5: updateSignFlow error
                    switch( signFlow.cancellationReasons )
                    {
                        case "create_error_code_1" :
                        case "create_error_code_2" :
                            msg = this.$t( "signflow.Error!DocumentError" );
                            break;
                        default :
                            msg = this.$t( "signflow.Error!Unexpected" );
                    }
                    this.$alert( { text : msg, acceptText : this.$t( "signflow.Ok" ) } ).then( () =>
                    {
                        this.terminate();
                    } );
                }
                else if ( !signFlow.active )
                {
                    timeout.setTimeout( () => this._doPollSignFlow(), 1000, "poll-sign-flow" );
                }
                else if( this.flowType === "only_me" )
                {
                    this.reset();
                    this.$router.push( `/sign?group_id=${this.groupId}&process_uuid=${signFlow.uuid}&event_uuid=${signFlow.signEvents[ 0 ].uuid}` );
                }
                else
                {
                    let msg = "";
                    if( signFlow.signEvents.filter( signEvent => signEvent.signerType === "others" ).length > 0 )
                    {
                        msg +=
                        this.$t( "signflow.RequestEmailSendSigners" ) + '\n\n' + this.$t(
                        "signflow.InformProgressNotifications" ) + '\n\n';
                    }
                    if( signFlow.signEvents.filter( signEvent => signEvent.signerType === "me" ).length > 0 )
                    {
                        msg += this.$t( "signflow.StartSigningNow" );
                        this.$confirm( {
                            text : msg,
                            acceptText : this.$t( "signflow.StartNow" ),
                            cancelText : this.$t( "signflow.Later" )
                        } ).then( r =>
                        {
                            this.terminate();
                            this.$router.push( `/sign?group_id=${this.groupId}&process_uuid=${signFlow.uuid}&event_uuid=${signFlow.signEvents[ 0 ].uuid}` );
                        } ).catch( e =>
                        {
                            this.terminate();
                        } );
                    }
                    else
                    {
                        this.$alert( { text : msg, acceptText : this.$t( "signflow.Ok" ) } ).then( r =>
                        {
                            this.terminate();
                        } );
                    }
                }
            } ).catch( e =>
            {
                console.error( e );
                this.$alert( {
                    text : this.$t( "signflow.Error!Unexpected" ),
                    acceptText : this.$t( "signflow.Ok" )
                } ).then( () =>
                {
                    this.terminate( e );
                } );
            } );
        }
    }
}
